import React from "react"
import styled from "styled-components"

import { MainWrapper, Layout } from "components"

const PageWrap = styled.div`
  color: var(--zombie);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  h3 {
    margin: 20px 0 10px 0;
    color: var(--black);
    font-family: "Oswald", sans-serif;
  }

  h1 {
    color: var(--black);
    font-family: "Oswald", sans-serif;
  }
`

export default function Terms() {
  return (
    <Layout>
      <div style={{ background: "var(--whiteChalk)" }}>
        <MainWrapper>
          <section style={{ padding: "150px 0" }}>
            <PageWrap>
              <div className="row">
                <div className="privacy-content">
                  <div className="text-center">
                    <h1 className="sans">Accessibility Policy & Statement</h1>
                    <div className="d-flex justify-content-center">
                      <p className="sans p-update">
                        Last updated: 14 April, 2022
                      </p>
                    </div>
                  </div>
                  <div className="p-content">
                    <div className="d-flex justify-content-left">
                      <h3 className="sans">General</h3>
                    </div>
                    <p>
                      <b>Neighbarista.com </b> strives to ensure that its
                      services are accessible to people with disabilities.{" "}
                      <b>Neighbarista.com </b> has invested a significant amount
                      of resources to help ensure that its website is made
                      easier to use and more accessible for people with
                      disabilities, with the strong belief that every person has
                      the right to live with dignity, equality, comfort and
                      independence.
                    </p>
                    <div className="d-flex justify-content-left">
                      <h3 className="sans">
                        Accessibility on <b>Neighbarista.com </b>
                      </h3>
                    </div>
                    <p>
                      <b>Neighbarista.com </b> makes available the UserWay
                      Website Accessibility Widget that is powered by a
                      dedicated accessibility server. The software allows{" "}
                      <b>Neighbarista.com </b> to improve its compliance with
                      the Web Content Accessibility Guidelines (WCAG 2.1).
                    </p>
                    <div className="d-flex justify-content-left">
                      <h3 className="sans">Enabling the Accessibility Menu</h3>
                    </div>
                    <p>
                      The <b>Neighbarista.com </b> accessibility menu can be
                      enabled by clicking the accessibility menu icon that
                      appears on the corner of the page. After triggering the
                      accessibility menu, please wait a moment for the
                      accessibility menu to load in its entirety.
                    </p>
                    <div className="d-flex justify-content-left">
                      <h3 className="sans">Disclaimer</h3>
                    </div>
                    <p>
                      <b>Neighbarista.com</b> continues its efforts to
                      constantly improve the accessibility of its site and
                      services in the belief that it is our collective moral
                      obligation to allow seamless, accessible and unhindered
                      use also for those of us with disabilities. Despite our
                      efforts to make all pages and content on{" "}
                      <b>Neighbarista.com </b> fully accessible, some content
                      may not have yet been fully adapted to the strictest
                      accessibility standards. This may be a result of not
                      having found or identified the most appropriate
                      technological solution.
                    </p>
                    <div className="d-flex justify-content-left">
                      <h3 className="sans">Here For You</h3>
                    </div>
                    <p>
                      If you are experiencing difficulty with any content on{" "}
                      <b>Neighbarista.com </b> or require assistance with any
                      part of our site, please contact us during normal business
                      hours as detailed below and we will be happy to assist.
                    </p>
                    <div className="d-flex justify-content-left">
                      <h3 className="sans">Contact Us</h3>
                    </div>
                    <p>
                      If you wish to report an accessibility issue, have any
                      questions or need assistance, please contact{" "}
                      <b>Neighbarista.com </b> Customer Support as follows:{" "}
                      <br /> <br />
                      Email:
                      <a
                        className="con-hover"
                        href="mailto:info@neighbarista.com"
                      >
                        info@neighbarista.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </PageWrap>
          </section>
        </MainWrapper>
      </div>
    </Layout>
  )
}
